var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.permFilterVendor() ? _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    }
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5"
  }, [_c('label', [_vm._v("Bulk Upload")]), _c('h4', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("Bulk Upload")])]), _c('div', {
    staticClass: "w-full px-4 py-4 mt-4 flex justify-between rounded-lg items-center mb-5 bg-white"
  }, [_c('div', {
    staticClass: "w-1/2"
  }, [_c('label', [_vm._v("Vendor")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Vendor",
      "open-direction": "bottom",
      "options": _vm.vendorSearchOptions,
      "searchable": true,
      "loading": _vm.isVendorSearch,
      "close-on-select": true,
      "multiple": false
    },
    on: {
      "search-change": _vm.vendorFind
    },
    model: {
      value: _vm.vendor,
      callback: function ($$v) {
        _vm.vendor = $$v;
      },
      expression: "vendor"
    }
  })], 1), _c('div', {
    staticClass: "w-1/2 flex mt-4 justify-end"
  }, [_c('button', {
    staticClass: "bg-white flex items-center py-2 px-3 border rounded-lg",
    on: {
      "click": _vm.ImportPopupBulkCSV
    }
  }, [_c('svg', {
    staticClass: "w-6 h-6 text-gray-800 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "currentColor",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm1.018 8.828a2.34 2.34 0 0 0-2.373 2.13v.008a2.32 2.32 0 0 0 2.06 2.497l.535.059a.993.993 0 0 0 .136.006.272.272 0 0 1 .263.367l-.008.02a.377.377 0 0 1-.018.044.49.49 0 0 1-.078.02 1.689 1.689 0 0 1-.297.021h-1.13a1 1 0 1 0 0 2h1.13c.417 0 .892-.05 1.324-.279.47-.248.78-.648.953-1.134a2.272 2.272 0 0 0-2.115-3.06l-.478-.052a.32.32 0 0 1-.285-.341.34.34 0 0 1 .344-.306l.94.02a1 1 0 1 0 .043-2l-.943-.02h-.003Zm7.933 1.482a1 1 0 1 0-1.902-.62l-.57 1.747-.522-1.726a1 1 0 0 0-1.914.578l1.443 4.773a1 1 0 0 0 1.908.021l1.557-4.773Zm-13.762.88a.647.647 0 0 1 .458-.19h1.018a1 1 0 1 0 0-2H6.647A2.647 2.647 0 0 0 4 13.647v1.706A2.647 2.647 0 0 0 6.647 18h1.018a1 1 0 1 0 0-2H6.647A.647.647 0 0 1 6 15.353v-1.706c0-.172.068-.336.19-.457Z",
      "clip-rule": "evenodd"
    }
  })]), _vm._v(" Import CSV ")])])]), _c('div', {
    staticClass: "bg-white block w-full px-3 py-3 rounded-lg"
  }, [_vm.isUpload ? _c('div', {
    staticClass: "flex-col w-full bg-white"
  }, [_c('div', {
    staticClass: "p-2"
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', {
    staticClass: "h-2 bg-gray-200 rounded-full w-full"
  }, [_c('div', {
    staticClass: "bg-blue-500 h-2 rounded-full",
    style: {
      width: _vm.progressBar + '%'
    }
  })])])])]) : _vm._e(), _c('div', {
    staticClass: "flex-col"
  }, _vm._l(_vm.statusUpload, function (item) {
    return _c('li', {
      key: item.item_name
    }, [item.error ? _c('span', [_vm._v(" " + _vm._s(item.item_name) + " - " + _vm._s(item.message) + " ")]) : item.is_new ? _c('span', [_c('router-link', {
      attrs: {
        "target": "_blank",
        "to": {
          name: _vm.DetailItems.name,
          params: {
            id: item.data.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(item.item_name) + " ")]), _vm._v(" - success uploaded ")], 1) : _c('span', [_c('router-link', {
      attrs: {
        "target": "_blank",
        "to": {
          name: _vm.DetailItems.name,
          params: {
            id: item.data.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(item.item_name) + " ")]), _vm._v(" - item already uploaded ")], 1), item.data ? _c('span', [item.data.single ? _c('span', [_vm._v(" with single offer "), _c('router-link', {
      attrs: {
        "target": "_blank",
        "to": {
          name: _vm.SingleEditPath.name,
          params: {
            id: item.data.single[0].id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(item.data.single[0].name) + " ")])], 1) : _vm._e()]) : _vm._e()]);
  }), 0)]), _c('form', {
    staticClass: "bg-white rounded-lg pb-4"
  }, [_c('div', {
    staticClass: "w-full overflow-y-auto"
  }, [_vm.isBulkAuthorShow() ? _c('button', {
    staticClass: "bg-blue-500 px-2 py-2 text-white rounded-lg mb-4 ml-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onBulkCreateAuthor
    }
  }, [_vm._v("Create all author")]) : _vm._e(), _c('t-table', {
    ref: "tableList",
    staticClass: "shadow-none w-full max-w-full",
    attrs: {
      "headers": _vm.fields,
      "data": _vm.bulkData
    },
    scopedSlots: _vm._u([{
      key: "thead",
      fn: function (props) {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          class: props.thClass
        }, [_vm._v(" Act ")]), _c('th', {
          staticClass: "text-center",
          class: props.thClass + ' sticky left-0 z-10'
        }, [_vm._v(" No ")]), _c('th', {
          class: props.thClass + ' sticky left-8 z-10',
          staticStyle: {
            "min-width": "200px"
          }
        }, [_vm._v(" Name (Item’s Title) ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Author ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" language ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Country ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" category ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Content type ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Item Type ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Release date ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Release Schedule ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Description ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Isbn ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Ios Tier ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Android Tier ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Printed Currency ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Printed Price ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Category Ax ")]), _c('th', {
          class: props.thClass
        }, [_vm._v(" Offers Premium ")])])])];
      }
    }, !_vm.bulkData.length ? {
      key: "tbody",
      fn: function (props) {
        return [_c('tbody', {
          class: props.tbodyClass
        }, [_c('td'), _c('td'), _c('td', {
          class: props.tdClass + ' sticky left-8 bg-white z-10 ',
          attrs: {
            "colspan": "20"
          }
        }, [_c('button', {
          staticClass: "border py-2.5 block px-3 rounded-lg my-0 w-48",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              return _vm.handleAdd();
            }
          }
        }, [_vm._v("Add New")])]), _c('tr', {
          class: [props.trClass, 'text-center']
        }, [_c('td', {
          class: props.tdClass,
          attrs: {
            "colspan": "20"
          }
        }, [_c('div', {
          staticClass: "flex-col items-center justify-center flex-nowrap flex h-96"
        }, [_c('svg', {
          staticClass: "w-24 h-24 text-gray-500 dark:text-white",
          attrs: {
            "aria-hidden": "true",
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "24",
            "height": "24",
            "fill": "none",
            "viewBox": "0 0 24 24"
          }
        }, [_c('path', {
          attrs: {
            "stroke": "currentColor",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            "d": "M10 3v4a1 1 0 0 1-1 1H5m4 8h6m-6-4h6m4-8v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z"
          }
        })]), _c('h4', {
          staticClass: "text-base text-gray-600 mb-0"
        }, [_vm._v("No Data")]), _c('p', {
          staticClass: "text-sm text-gray-500"
        }, [_vm._v("There are no data to display")])])])])])];
      }
    } : null, {
      key: "row",
      fn: function (props) {
        return [props.rowIndex === 0 ? _c('tr', [_c('td'), _c('td'), _c('td', {
          class: props.tdClass + ' sticky left-8 bg-white z-10 '
        }, [_c('button', {
          staticClass: "border py-2.5 block px-3 rounded-lg my-0 w-full",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              return _vm.handleAdd();
            }
          }
        }, [_vm._v("Add New")])]), _c('td', {
          staticClass: "bg-white",
          attrs: {
            "colspan": "20"
          }
        })]) : _vm._e(), _c('tr', {
          staticClass: "border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3"
        }, [_c('td', {
          staticClass: "w-3 text-center",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('i', {
          staticClass: "fa fa-trash fa-2 text text-gray-500",
          staticStyle: {
            "cursor": "pointer",
            "font-size": "24px"
          },
          on: {
            "click": function ($event) {
              return _vm.handleDelete(props);
            }
          }
        })])]), _c('td', {
          staticClass: "uppercase text-center",
          class: props.tdClass + ' sticky left-0  bg-white z-10'
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_vm._v(" " + _vm._s(props.rowIndex + 1) + " ")])]), _c('td', {
          class: props.tdClass + ' sticky left-8 z-10 bg-white',
          staticStyle: {
            "min-width": "200px"
          }
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.bulkData[props.rowIndex].name,
            expression: "bulkData[props.rowIndex].name"
          }],
          staticClass: "border p-2 rounded-lg",
          domProps: {
            "value": _vm.bulkData[props.rowIndex].name
          },
          on: {
            "input": function ($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.bulkData[props.rowIndex], "name", $event.target.value);
            }
          }
        })])]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('multiselect', {
          staticClass: "w-60",
          attrs: {
            "label": "name",
            "track-by": "name",
            "placeholder": "Enter Author",
            "open-direction": "bottom",
            "options": _vm.authorSearchOptions,
            "loading": _vm.isAuthorSearch,
            "searchable": true,
            "close-on-select": true,
            "multiple": true
          },
          on: {
            "search-change": _vm.authorFind
          },
          model: {
            value: _vm.bulkData[props.rowIndex].author,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "author", $$v);
            },
            expression: "bulkData[props.rowIndex].author"
          }
        }), _vm._l(_vm.authorNotFound[props.rowIndex], function (author) {
          return _c('div', {
            key: author
          }, [_c('div', {
            staticClass: "relative top-2",
            attrs: {
              "cols": "12"
            }
          }, [_vm._v(" " + _vm._s(author) + " "), _c('button', {
            staticClass: "bg-blue-500 rounded-lg p-1 px-2 text-white ml-2",
            attrs: {
              "type": "button"
            },
            on: {
              "click": function ($event) {
                return _vm.onCreateAuthor(author);
              }
            }
          }, [_vm._v("create")])])]);
        })], 2)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('multiselect', {
          staticClass: "w-60",
          attrs: {
            "placeholder": "Enter languages",
            "open-direction": "bottom",
            "label": "name",
            "track-by": "name",
            "custom-label": _vm.nameWithISO,
            "options": _vm.languagesSearchOptions,
            "searchable": true,
            "close-on-select": true,
            "multiple": true
          },
          model: {
            value: _vm.bulkData[props.rowIndex].languages,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "languages", $$v);
            },
            expression: "bulkData[props.rowIndex].languages"
          }
        })], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('multiselect', {
          staticClass: "w-60",
          attrs: {
            "placeholder": "Enter Countries",
            "open-direction": "bottom",
            "label": "name",
            "track-by": "name",
            "custom-label": _vm.nameWithISO,
            "options": _vm.countriesSearchOptions,
            "searchable": true,
            "close-on-select": true,
            "multiple": true
          },
          model: {
            value: _vm.bulkData[props.rowIndex].countries,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "countries", $$v);
            },
            expression: "bulkData[props.rowIndex].countries"
          }
        })], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('multiselect', {
          staticClass: "w-60",
          attrs: {
            "label": "name",
            "track-by": "name",
            "placeholder": "Enter Categories",
            "open-direction": "bottom",
            "options": _vm.categoriesSearchOptions,
            "loading": _vm.isCategoriesSearch,
            "searchable": true,
            "close-on-select": true,
            "multiple": true
          },
          on: {
            "search-change": function ($event) {
              return _vm.categoriesFind($event, _vm.bulkData[props.rowIndex]);
            }
          },
          model: {
            value: _vm.bulkData[props.rowIndex].categories,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "categories", $$v);
            },
            expression: "bulkData[props.rowIndex].categories"
          }
        })], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('t-select-table', {
          staticClass: "w-24",
          attrs: {
            "options": _vm.contentTypeOptions
          },
          model: {
            value: _vm.bulkData[props.rowIndex].content_type,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "content_type", $$v);
            },
            expression: "bulkData[props.rowIndex].content_type"
          }
        })], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('t-select-table', {
          staticClass: "w-24",
          attrs: {
            "options": _vm.itemTypeOptions
          },
          model: {
            value: _vm.bulkData[props.rowIndex].item_type,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "item_type", $$v);
            },
            expression: "bulkData[props.rowIndex].item_type"
          }
        })], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('DatetimeCustom', {
          attrs: {
            "timeDate": false,
            "label": ''
          },
          model: {
            value: _vm.bulkData[props.rowIndex].release_date,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "release_date", $$v);
            },
            expression: "bulkData[props.rowIndex].release_date"
          }
        })], 1), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('DatetimeCustom', {
          attrs: {
            "timeDate": false,
            "label": ''
          },
          model: {
            value: _vm.bulkData[props.rowIndex].release_schedule,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "release_schedule", $$v);
            },
            expression: "bulkData[props.rowIndex].release_schedule"
          }
        })], 1), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "w-96 block h-auto mt-4"
        }, [_c('textarea', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.bulkData[props.rowIndex].description,
            expression: "bulkData[props.rowIndex].description"
          }],
          staticClass: "w-full h-11 rounded-lg border mt-0 text-gray-700",
          domProps: {
            "value": _vm.bulkData[props.rowIndex].description
          },
          on: {
            "input": function ($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.bulkData[props.rowIndex], "description", $event.target.value);
            }
          }
        })])]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3 w-60"
        }, [_c('t-input', {
          staticClass: "w-20",
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.bulkData[props.rowIndex].isbn,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "isbn", $$v);
            },
            expression: "bulkData[props.rowIndex].isbn"
          }
        })], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3 w-64 relative"
        }, [_c('multiselect', {
          attrs: {
            "label": "tier_code",
            "track-by": "tier_code",
            "placeholder": "Ios Tier",
            "open-direction": "bottom",
            "options": _vm.iosTierSearchOptions,
            "searchable": true,
            "loading": _vm.isIosTierSearch,
            "close-on-select": true,
            "multiple": false
          },
          on: {
            "search-change": _vm.iosTierFind
          },
          model: {
            value: _vm.bulkData[props.rowIndex].ios_tier,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "ios_tier", $$v);
            },
            expression: "bulkData[props.rowIndex].ios_tier"
          }
        }), _c('em', {
          staticClass: "absolute top-11"
        }, [_vm._v(" IDR : " + _vm._s(_vm._f("formatPriceId")(_vm.bulkData[props.rowIndex].ios_tier ? _vm.bulkData[props.rowIndex].ios_tier.price_idr : 0)) + " ")])], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3 w-63 relative"
        }, [_c('multiselect', {
          staticStyle: {
            "min-width": "250px"
          },
          attrs: {
            "label": "tier_code",
            "track-by": "tier_code",
            "placeholder": "Android Tier",
            "open-direction": "bottom",
            "options": _vm.androidTierSearchOptions,
            "searchable": true,
            "loading": _vm.isAndroidTierSearch,
            "close-on-select": true,
            "multiple": false
          },
          on: {
            "search-change": _vm.androidTierFind
          },
          model: {
            value: _vm.bulkData[props.rowIndex].android_tier,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "android_tier", $$v);
            },
            expression: "bulkData[props.rowIndex].android_tier"
          }
        }), _c('em', {
          staticClass: "absolute top-11"
        }, [_vm._v(" IDR : " + _vm._s(_vm._f("formatPriceId")(_vm.bulkData[props.rowIndex].android_tier ? _vm.bulkData[props.rowIndex].android_tier.price_idr : 0)) + " ")])], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3 w-63 relative"
        }, [_c('t-select', {
          staticStyle: {
            "width": "100px"
          },
          attrs: {
            "options": _vm.currencyOptions
          },
          model: {
            value: _vm.bulkData[props.rowIndex].printed_currency,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "printed_currency", $$v);
            },
            expression: "bulkData[props.rowIndex].printed_currency"
          }
        }, [_vm._v(" >")])], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3 w-63 relative"
        }, [_c('t-input', {
          staticStyle: {
            "width": "100px"
          },
          attrs: {
            "options": _vm.currencyOptions
          },
          model: {
            value: _vm.bulkData[props.rowIndex].printed_price,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "printed_price", $$v);
            },
            expression: "bulkData[props.rowIndex].printed_price"
          }
        }, [_vm._v(" >")])], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3 w-63 relative"
        }, [_c('multiselect', {
          staticStyle: {
            "min-width": "200px"
          },
          attrs: {
            "label": "name",
            "track-by": "name",
            "placeholder": "Category Ax",
            "open-direction": "bottom",
            "options": _vm.axSearchOptions,
            "searchable": true,
            "loading": _vm.isAxSearch,
            "close-on-select": true,
            "multiple": false
          },
          on: {
            "search-change": _vm.axFind
          },
          model: {
            value: _vm.bulkData[props.rowIndex].category_ax,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "category_ax", $$v);
            },
            expression: "bulkData[props.rowIndex].category_ax"
          }
        })], 1)]), _c('td', {
          staticClass: "uppercase",
          class: props.tdClass
        }, [_c('div', {
          staticClass: "mt-3 w-63 relative"
        }, [_c('multiselect', {
          staticStyle: {
            "min-width": "200px"
          },
          attrs: {
            "label": "name",
            "track-by": "name",
            "placeholder": "Offers Premium",
            "open-direction": "bottom",
            "options": _vm.offersSearchOptions,
            "searchable": true,
            "loading": _vm.isOffersSearch,
            "close-on-select": true,
            "multiple": true
          },
          model: {
            value: _vm.bulkData[props.rowIndex].offers_premium,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[props.rowIndex], "offers_premium", $$v);
            },
            expression: "bulkData[props.rowIndex].offers_premium"
          }
        })], 1)])])];
      }
    }], null, true)
  })], 1), _c('t-modal-file', {
    ref: "importCSVPopup"
  }, [_c('div', {
    staticClass: "mx-auto p-2 bg-white rounded-lg"
  }, [_c('h2', {
    staticClass: "text-xl font-bold mb-2"
  }, [_vm._v("File Upload")]), _c('div', {
    staticClass: "flex flex-col items-center justify-center bg-gray-50 rounded-lg p-6 hover:border-blue-500 cursor-pointer",
    on: {
      "click": _vm.triggerFileInput,
      "drop": function ($event) {
        $event.preventDefault();
        return _vm.handleFileDrop.apply(null, arguments);
      },
      "dragover": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('svg', {
    staticClass: "w-6 h-6 text-gray-500 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M12 5v9m-5 0H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2M8 9l4-5 4 5m1 8h.01"
    }
  })]), _c('p', {
    staticClass: "mt-2 text-gray-500 mb-1 font-light"
  }, [_c('span', {
    staticClass: "text-gray-600 font-semibold cursor-pointer"
  }, [_vm._v("Click to upload")]), _vm._v(" or drag and drop ")]), _c('p', {
    staticClass: "text-gray-500 font-semibold"
  }, [_vm._v("Max. 10,000 SKU")]), _c('button', {
    staticClass: "mt-0 bg-blue-500 flex text-white py-2.5 px-4 rounded-lg hover:bg-blue-600",
    attrs: {
      "type": "button"
    }
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-white mr-2 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-width": "2",
      "d": "m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
    }
  })]), _vm._v(" Browse File ")]), _c('input', {
    ref: "fileInput",
    staticClass: "hidden",
    attrs: {
      "type": "file",
      "accept": ".csv"
    },
    on: {
      "change": _vm.handleFileChange
    }
  })]), _c('p', {
    staticClass: "mt-4 text-sm text-center text-gray-500"
  }, [_vm._v(" To download CSV template, "), _c('a', {
    staticClass: "text-blue-500 font-semibold underline",
    attrs: {
      "href": _vm.URL_STATIC.TEMPLATE_BULK_CREATE
    }
  }, [_vm._v("Click Here")])])])]), _c('t-modal-file-progress-bar', {
    ref: "progressPopup",
    staticClass: "w-1/2"
  }, [_c('div', {
    staticClass: "w-full mx-auto p-0 bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "mt-1 w-full"
  }, [_c('h2', {
    staticClass: "text-xl font-bold mb-0"
  }, [_vm._v("Upload Progress")]), _c('div', {
    staticClass: "flex justify-start items-center"
  }, [_c('div', {
    staticClass: "pt-3"
  }, [_c('svg', {
    staticClass: "w-6 h-6 text-gray-500 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "currentColor",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm1.018 8.828a2.34 2.34 0 0 0-2.373 2.13v.008a2.32 2.32 0 0 0 2.06 2.497l.535.059a.993.993 0 0 0 .136.006.272.272 0 0 1 .263.367l-.008.02a.377.377 0 0 1-.018.044.49.49 0 0 1-.078.02 1.689 1.689 0 0 1-.297.021h-1.13a1 1 0 1 0 0 2h1.13c.417 0 .892-.05 1.324-.279.47-.248.78-.648.953-1.134a2.272 2.272 0 0 0-2.115-3.06l-.478-.052a.32.32 0 0 1-.285-.341.34.34 0 0 1 .344-.306l.94.02a1 1 0 1 0 .043-2l-.943-.02h-.003Zm7.933 1.482a1 1 0 1 0-1.902-.62l-.57 1.747-.522-1.726a1 1 0 0 0-1.914.578l1.443 4.773a1 1 0 0 0 1.908.021l1.557-4.773Zm-13.762.88a.647.647 0 0 1 .458-.19h1.018a1 1 0 1 0 0-2H6.647A2.647 2.647 0 0 0 4 13.647v1.706A2.647 2.647 0 0 0 6.647 18h1.018a1 1 0 1 0 0-2H6.647A.647.647 0 0 1 6 15.353v-1.706c0-.172.068-.336.19-.457Z",
      "clip-rule": "evenodd"
    }
  })])]), _c('div', {
    staticClass: "ml-2 pt-3"
  }, [_c('p', {
    staticClass: "text-left mb-0 text-sm text-gray-700 font-semibold"
  }, [_vm._v(" " + _vm._s(this.fileName))]), _c('p', {
    staticClass: "text-left text-sm text-gray-500 mb-0"
  }, [_vm._v(_vm._s(_vm.readableFileSize(this.fileSize)))])])]), _c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', {
    staticClass: "h-2 bg-gray-200 rounded-full w-11/12"
  }, [_c('div', {
    staticClass: "bg-blue-500 h-2 rounded-full",
    style: {
      width: _vm.progress + '%'
    }
  })]), _c('div', {
    staticClass: "text-left text-sm text-gray-500"
  }, [_vm._v(" " + _vm._s(_vm.progress) + "% ")])])])])]), _vm.bulkData.length ? _c('div', {
    staticClass: "px-3 py-4"
  }, [_c('button', {
    staticClass: "py-2.5 px-4 mr-3 bg-blue-500 text-white rounded-lg border",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.onUpload
    }
  }, [_vm._v("Submit")]), _c('button', {
    staticClass: "py-2.5 px-4 bg-gray-300 rounded-lg border",
    attrs: {
      "type": "click"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v("Cancel")])]) : _vm._e()], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }